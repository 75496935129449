import React from 'react';
import { FaHome, FaBook, FaGithub, FaDiscord } from 'react-icons/fa'; // Importing specific icons including Discord
import logo from '../logo.png';
import sampleScript1 from '../image_lib/executor/sample_script.png'; // Importing the first image
import sampleScript2 from '../image_lib/vend/sample_picture.png'; // Importing the second image
import sampleScript3 from '../image_lib/executor/sample_script3.png'; // Importing the third image
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Importing a dark theme for syntax highlighting
import '../App.css';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="header-title">餞 - Hanamuke</h1>
        <p className="header-subtitle">By Yuhkil</p>
        {/* <div className="discord-widget">
          <iframe src="https://discord.com/widget?id=1111072687972954182&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
        </div> */}
      </header>
      
      {/* New Examples Section */}
      <section className="examples-section">
        <h2>Features</h2>
        <div className="image-cards"> {/* Use the new image-cards class here */}
          <div className="image-card">
            <h3>Make Custom Scripts</h3>
            <img src={sampleScript1} alt="Executor 1" className="responsive-image" />
          </div>
          <div className="image-card">
            <h3>AFK Modes</h3>
            <img src={sampleScript2} alt="Vend Mode" className="responsive-image" />
          </div>
          {/* <div className="image-card">
            <h3>Visuals</h3>
            <img src={sampleScript3} alt="Executor 3" className="responsive-image" />
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Home;
