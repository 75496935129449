import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FaHome, FaBook, FaGithub, FaDiscord, FaArrowUp } from 'react-icons/fa';
import Home from './pages/Home';
import Documentation from './pages/Documentation';
import NotFound from './pages/NotFound'; // Import the NotFound component
import logo from './logo.png';
import './App.css';

function App() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleNavigation = (path) => {
    window.location.href = path; // Force a hard refresh
  };

  return (
    <Router>
      <div className="App">
        {/* Global Navigation Bar */}
        <nav className="top-nav">
          <div className="top-nav__logo">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <div className="top-nav__links">
            <a href="/" onClick={() => handleNavigation('/')}>
              <FaHome /> Home
            </a>
            <a href="/documentation" onClick={() => handleNavigation('/documentation')}>
              <FaBook /> Documentation
            </a>
            <a href="https://github.com/GrowHax" target="_blank" rel="noopener noreferrer">
              <FaGithub /> GitHub
            </a>
            <a href="https://discord.gg/SES9tgHEHE" target="_blank" rel="noopener noreferrer">
              <FaDiscord /> Discord
            </a>
          </div>
        </nav>

        {/* Page Content */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all route for undefined paths */}
        </Routes>

        {/* Footer */}
        <footer className="App-footer">
          <p style={{ marginLeft: 'auto' }}>
            &copy; {new Date().getFullYear()} Hanamuke
          </p>
        </footer>

        {/* Go to Top Button */}
        <button className="go-top" onClick={scrollToTop}>
          <FaArrowUp />
        </button>
      </div>
    </Router>
  );
}

export default App;