import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

function Documentation() {
  return (
    <div>
      <h1>Hanamuke Documentation</h1>

      {/* Documentation Section */}
      <section className="examples-section">
        <h2>Functions</h2>
        <ul>
          <li><a href="#FindPath">FindPath</a></li>
          <li><a href="#GetTile">GetTile</a></li>
          <li><a href="#log">log</a></li>
          <li><a href="#SendPacket">SendPacket</a></li>
          <li><a href="#SendPacketRaw">SendPacketRaw</a></li>
          <li><a href="#SendPacketRawClient">SendPacketRawClient</a></li>
          <li><a href="#Sleep">Sleep</a></li>
          <li><a href="#SendVarlist">SendVarlist</a></li>
          <li><a href="#GetTiles">GetTiles</a></li>
          <li><a href="#GetObjects">GetObjects</a></li>
          <li><a href="#GetInventory">GetInventory</a></li>
          <li><a href="#GetPlayers">GetPlayers</a></li>
          <li><a href="#AddCallback">AddCallback</a></li>
          <li><a href="#RemoveCallbacks">RemoveCallbacks</a></li>
          <li><a href="#CollectItems">CollectItems</a></li>
          <li><a href="#GetItemCount">GetItemCount</a></li>
          <li><a href="#GetPing">GetPing</a></li>
          <li><a href="#SendWebhook">SendWebhook</a></li>
          <li><a href="#Timer">Timer</a></li>
          <li><a href="#IsSolid">IsSolid</a></li>
          <li><a href="#GetAccesslist">GetAccesslist</a></li>
          <li><a href="#PathFind">PathFind</a></li>
          <li><a href="#RunThread">RunThread</a></li>
        </ul>
        <br>
        </br>
        
        {/* Example: SendPacket */}
        <div className="example-card" id="SendPacket">
          <h3>SendPacket</h3>
          <p><strong>Usage: </strong> Sends a direct packet.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example Usage:
SendPacket(2, "action|input\n|text| \`#Hi from yuhkil")
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: SendPacketRaw */}
        <div className="example-card" id="SendPacketRaw">
          <h3>SendPacketRaw</h3>
          <p><strong>Usage:</strong> Sends a GamePacket to the server.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
function punch(x, y)
    local pkt = {}
    pkt.type = 3
    pkt.int_data = 18
    pkt.pos_x = GetLocal().pos_x
    pkt.pos_y = GetLocal().pos_y
    pkt.int_x = GetLocal().pos_x // 32 + x
    pkt.int_y = GetLocal().pos_y // 32 + y
    pkt.flags = 2560
    SendPacketRaw(pkt)
end

punch(1,0)
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: SendPacketRawClient */}
        <div className="example-card" id="SendPacketRawClient">
          <h3>SendPacketRawClient</h3>
          <p><strong>Usage:</strong> Sends a GamePacket to the client.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example usage (Adds Golden Heartbow to inventory (client)):
local packet = {}
packet.type = 13
packet.int_data = 1464
packet.count2 = 1
SendPacketRawClient(packet)
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: log */}
        <div className="example-card" id="log">
          <h3>log</h3>
          <p><strong>Usage:</strong> Prints a message to the game's console.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example usage:
log("Hello There")
            `}
          </SyntaxHighlighter>
 </div>

        {/* Example: GetInventory */}
        <div className="example-card" id="GetInventory">
          <h3>GetInventory</h3>
          <p><strong>Usage:</strong> Returns information from the player's inventory.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example usage:
for _, item in pairs(GetInventory()) do
  print(item.id)
end
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: GetPlayers */}
        <div className="example-card" id="GetPlayers">
          <h3>GetPlayers</h3>
          <p><strong>Usage:</strong> Returns a list of players (net_avatar table).</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example usage:
local players = GetPlayers()
if players then
    for i, player in ipairs(players) do
        print("  Position X: " .. player.pos_x)
        print("  Position Y: " .. player.pos_y)
        print("  Size X: " .. player.size_x)
        print("  Size Y: " .. player.size_y)
        print("  Name: " .. player.name)
        print("  Facing Left: " .. tostring(player.facing_left))
        print("  User ID: " .. player.uid)
        print("  Net ID: " .. player.netid)
        print("  Country: " .. player.country)
    end
else
    print("No players found")
end
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: FindPath */}
        <div className="example-card" id="FindPath">
          <h3>FindPath</h3>
          <p><strong>Usage:</strong> Teleports the player to the best path to the destination.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example usage:
FindPath(23, 50)
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: SendWebhook */}
        <div className="example-card" id="SendWebhook">
          <h3>SendWebhook</h3>
          <p><strong>Usage:</strong> Sends a webhook message in JSON format.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example Usage:
local payload = [[
{
    "content": "",
    "embeds": [{
        "title": "watehel",
        "description": "okay www.",
        "url": "https://github.com/GrowHax/Hanamuke",
        "color": 16777215,
        "fields": [{
            "name": "whatever",
            "value": "hey..."
        }],
        "author": {
            "name": "Yuhkil",
            "url": "https://example.com",
            "icon_url": "https://i.imgur.com/UvYjKOY.png"
        },
        "footer": {
            "text": "Sent from Hanamuke",
            "icon_url": "https://i.imgur.com/dDq7U7m.png"
        },
        "image": {
            "url": "https://i.imgur.com/WUlqAxg.png"
        },
        "thumbnail": {
            "url": "https://i.imgur.com/Wp6TaZi.png"
        },
        "timestamp": "2023-02-15T17:00:00.000Z"
    }]
}
]]
local webhook = "your webhook url"
SendWebhook(webhook, payload)
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: GetTile */}
        <div className="example-card" id="GetTile">
          <h3>GetTile</h3>
          <p><strong>Usage:</strong> Gives information about a Tile.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example Usage:
local xx = GetLocal().pos_x // 32
local yy = GetLocal().pos_y // 32
local tile = GetTile(xx,yy)
print("Foreground:", tile.fg)
print("Background:", tile.bg)
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: GetTiles */}
        <div className="example-card" id="GetTiles">
          <h3>GetTiles</h3>
          <p><strong>Usage:</strong> Gives info of ALL tiles in the world.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example Usage:
for i,tile in pairs(GetTiles()) do
	log(tile.fg)
end
            `}
 </SyntaxHighlighter>
        </div>

        {/* Example: Sleep */}
        <div className="example-card" id="Sleep">
          <h3>Sleep</h3>
          <p><strong>Usage:</strong> Delays execution for a specified amount of milliseconds.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example Usage:
log("Start script")
Sleep(2000) -- delay 2 seconds
log("2 seconds later")
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: SendVarlist */}
        <div className="example-card" id="SendVarlist">
          <h3>SendVarlist</h3>
          <p><strong>Usage:</strong> Sends a variable list to the client.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example Usage:
if GetLocal().name ~= nil then
    local me = GetLocal()
    local var = {}
    var[0] = "OnAddNotification"
    var[1] = "interface/atomic_button.rttex"
    var[2] = "Warning from \`4System\`0: You've been \`4BANNED\`0 from Growtopia for 730 days"
    var[3] = "audio/hub_open.wav"
    var.netid = -1 -- must be set otherwise it won't work
    SendVarlist(var)
end
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: AddCallback */}
        <div className="example-card" id="AddCallback">
          <h3>AddCallback</h3>
          <p><strong>Usage:</strong> Adds a Lua function to be called when a specific event occurs in the game.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example Usage:
AddCallback("Hook", "OnPacket", function(type, packet)
  print(packet)
end)
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: RemoveCallbacks */}
        <div className="example-card" id="RemoveCallbacks">
          <h3>RemoveCallbacks</h3>
          <p><strong>Usage:</strong> Removes all Lua functions that were added with AddCallback.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example Usage:
RemoveCallbacks()
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: GetObjects */}
        <div className="example-card" id="GetObjects">
          <h3>GetObjects</h3>
          <p><strong>Usage:</strong> Returns objects from the Object table.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example Usage:
for i,obj in pairs(GetObjects()) do
	log(obj.id)
end
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: CollectItems */}
        <div className="example-card" id="CollectItems">
          <h3>CollectItems</h3>
          <p><strong>Usage:</strong> Auto Collects Objects within specified tile range.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example Usage:
CollectItems(2)
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: GetItemCount */}
        <div className="example-card" id="GetItemCount">
          <h3>GetItemCount</h3>
          <p><strong>Usage:</strong> Returns count of given item id.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example Usage:
log(GetItemCount(2))
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: GetPing */}
        <div className="example-card" id="GetPing">
          <h3>GetPing</h3>
          <p><strong>Usage:</strong> Returns game ping.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example Usage:
log(GetPing())
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: Timer */}
        <div className="example-card" id="Timer">
          <h3>Timer</h3>
          <p><strong>Usage:</strong> You can find the documentation here: <a href="https://wiki.facepunch.com/gmod/timer">Timer Library</a></p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example Usage:
AddCallback("timer", "OnUpdate", function(deltatime )
    timer.Update(deltatime)
end)

-- second arg is delay (2 seconds in this case)
-- third arg is repeat amount (0 = infinite, 1 or more = repeat to certain amount) 
timer.Create("timer_example", 2, 0, function()
    print("LinusTouchTips") -- prints on the console every 2 seconds
end)

timer.Destroy("timer_example") -- this will delete the timer when present
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: IsSolid */}
        <div className="example-card" id="IsSolid">
          <h3>IsSolid</h3>
          <p><strong>Usage:</strong> Returns true if tile is solid - Returns false if tile is not solid.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example usage:
local x = GetLocal().pos_x // 32
local y = GetLocal().pos_y // 32
print(IsSolid(x, y)) -- if solid block it will return true
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: GetAccesslist */}
        <div className="example-card" id="GetAccesslist">
          <h3>GetAccesslist</h3>
          <p><strong>Usage:</strong> Returns uids from people with world lock access.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example usage:
for __, v in pairs(GetAccesslist(49, 12)) do
    log(v.uid) -- don't forget to pass key uid
end
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: PathFind */}
        <div className="example-card" id="PathFind">
          <h3>PathFind</h3>
          <p><strong>Usage:</strong> Calculates the path (useful for FindPath).</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example usage:
local path = PathFind(2, 23)
for i, v in ipairs(path) do
    print(("%d, %d"):format(v.x, v.y))
end
            `}
          </SyntaxHighlighter>
        </div>

        {/* Example: RunThread */}
        <div className="example-card" id="RunThread">
          <h3>RunThread</h3>
          <p><strong>Usage:</strong> Run functions inside a separate thread.</p>
          <SyntaxHighlighter language="lua" style={dracula}>
            {`
-- Example usage:
RunThread(function()
    log("Hi")
    Sleep(1000)
end)
            `}
          </SyntaxHighlighter>
        </div>

      </section>
    </div>
  );
}

export default Documentation;